globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"flY3SotQE_f9vrxAr9Vfy"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

// eslint-disable-next-line no-underscore-dangle
const pkJson = { version: window.__version };

Sentry.init({
  enabled: process.env.NODE_ENV !== 'development',

  environment: window.APP_ENV,
  release: pkJson.version,

  dsn: 'https://1d66a700f9cefd64a36e447b41097a58@o4507179813699584.ingest.de.sentry.io/4507226049151056',

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.APP_ENV === 'production' ? 0.3 : 0,
  replaysOnErrorSampleRate: process.env.APP_ENV === 'production' ? 0.5 : 0.1,
  replaysSessionSampleRate: process.env.APP_ENV === 'production' ? 0.1 : 0,

  ignoreErrors: ['Non-Error promise rejection captured with value: false'],
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://lumirental.com'],

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.browserTracingIntegration({
      traceFetch: false,
    }),
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
